$brand-colour: #639;
$brand-font: "Avenir Next","Helvetica Neue",Arial,sans-serif;

html, body {
  background-color: #f6f6f6
}

main > section {
  padding: 40px;
  &:first-child { background-color: #fff; }
}


header, nav {
  text-align: center;
  background: $brand-colour;
  padding: 10px 0;
  a, a:hover, a:active {
    color: #fff;
    text-decoration: none;
  }
  h1 {
    margin-bottom: -0.05em;
    padding: 0.1em 0;
    text-shadow: 0px 1px 5px rgba(0,0,0,0.5);
    @media (max-width: 380px) {
      & { font-size: 2rem !important; }
    }
    @media (min-width: 381px) and (max-width: 520px) {
      & { font-size: 2.5rem !important; }
    }
  }
}
nav {
  background: darken($brand-colour, 10%);
  font-family: $brand-font;
  letter-spacing: -0.025em;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: block;
    margin: 0 15px;
    &, &:hover { color: #fff; }
    &:active { color: #ccc; }
  }
}

article {
  padding: 40px 0;
  &:not(:last-child) { border-bottom: 1px solid #ddd; }
  small {
    display: block;
    margin-top: 10px;
  }
}

.brands {
  padding-bottom: 0;
  h2 { margin-bottom: 1.5rem; }
  div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    a:not(:last-child) {
      margin-right: 1.5rem;
    }
    img {
      height: 60px;
    }
  }
}

.phone {
  text-align: center;
  padding-bottom: 0;
  h4 {
    font-family: $brand-font;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin-bottom: 0;
  }
  a {
    display: block;
    font-size: 3em;
    color: $brand-colour;
    font-family: $brand-font;
    font-weight: bold;
    letter-spacing: -0.05em;
    &:hover, &:active {
      color: darken($brand-colour, 15%);
      text-decoration: none;
    }
  }
  small {
    display: block;
  }
}

h1, h2, h3, span.logo {
  font-family: $brand-font;
  font-weight: 400 !important;
  letter-spacing: -0.05em;
  strong { font-weight: 600; }
  span, &.logo { font-weight: 100 !important; }
  span.star {
    font-size: 0.75em;
    top: -0.09em;
    position: relative;
    margin-right: 0.25em;
  }
  sup {
    font-size: 0.3em;
    top: -1.5em;
    font-weight: 100;
  }
}
span.logo sup {
  font-size: 0.7em;
  top: -0.5em;
}

footer {
  font-size: 0.8em;
  color: #999;
  text-align: center;
  margin: 40px 0;
  span.logo.logo { font-weight: 400 !important; }
}
